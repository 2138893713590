import request from './request.js'
// /project/getProject?projectid=867929034&agencyid=1
export function getWebBlock(data){
  return request({
    url: 'arcweb/getWebBlock',
    method: 'post',
    params: data
  });
}
//测试  提交
