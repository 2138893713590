export function getNowFormatDate(date) {
  if(!date){
    date = new Date();
  }
  let seperator1 = "-";
  let seperator2 = ":";
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  let HH =date.getHours();
  if (HH >= 0 && HH <= 9) {
    HH = "0" + HH;
  }
  let MM =date.getMinutes();
  if (MM >= 0 && MM <= 9) {
    MM = "0" + MM;
  }
  let SS =date.getSeconds();
  if (SS >= 0 && SS <= 9) {
    SS = "0" + SS;
  }
  let currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + " " + HH + seperator2 + MM + seperator2 + SS;
  return currentdate;
}

export function newDate(strDate) {
  return new Date(strDate.replace(/\-/g,"/"));
}
// 当地时间转格林时间
export function toGmtTime(time){
  let timeNum = newDate(time).getTime()+(new Date().getTimezoneOffset()*60000);
  let dateTime = new Date(timeNum)
  return getNowFormatDate(dateTime)
}
export function toLocalTime(time,offset=0){
  if (time) {
    let n = time.indexOf(".");
    if (n != -1) {
      time = time.substring(0, n);
    }
  }
  let timeNum = newDate(time).getTime()-(new Date().getTimezoneOffset()*60000) + offset;
  let dateTime = new Date(timeNum)
  return getNowFormatDate(dateTime)
}
export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
  fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
  'M+': date.getMonth() + 1,
  'd+': date.getDate(),
  'h+': date.getHours(),
  'm+': date.getMinutes(),
  's+': date.getSeconds()
  };
  for (let k in o) {
  if (new RegExp(`(${k})`).test(fmt)) {
  let str = o[k] + '';
  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
  }
  }
  return fmt;
  }
  function padLeftZero (str) {
  return ('00' + str).substr(str.length);
}
export function strDate(date) {
  let mo ={
    '01':"Jan",
    '02':"Feb",
    '03':"Mar",
    '04':"Apr",
    '05':"May",
    '06':"Jun",
    '07':"Jul",
    '08':"Aug",
    '09':"Sep",
    '10':"Oct",
    '11':"Nov",
    '12':"Dec",
  }
  if(!date){
    return ''
  }
  let time = date.substring(0,19);    
  time = time.replace(/-/g,'/'); 
  let now = new Date(time);
  let str = '';
  let y = now.getFullYear();
  let m = now.getMonth() +1;
  let d = now.getDate();
  let h = now.getHours();
  let mm = now.getMinutes();
  let s = now.getSeconds();
  if(h>=12) {
    h -= 12;
    str = " PM";
  }else{
    str = " AM";
  }
  h = h < 10 ? "0" + h : h;
  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  let xy =mo[m] + ' ' + d + ', '+ y;
  return xy;
}
export function AmPmDate(date) {
  let mo ={
    '01':"Jan",
    '02':"Feb",
    '03':"Mar",
    '04':"Apr",
    '05':"May",
    '06':"Jun",
    '07':"Jul",
    '08':"Aug",
    '09':"Sep",
    '10':"Oct",
    '11':"Nov",
    '12':"Dec",
  }
  if(!date){
    return ''
  }
  let time = date.substring(0,19);    
  time = time.replace(/-/g,'/'); 
  let now = new Date(time);
  let str = '';
  let y = now.getFullYear();
  let m = now.getMonth() +1;
  let d = now.getDate();
  let h = now.getHours();
  let mm = now.getMinutes();
  let s = now.getSeconds();
  if(h>=12) {
    h -= 12;
    str = " PM";
  }else{
    str = " AM";
  }
  h = h < 10 ? "0" + h : h;
  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  let xy =mo[m] + ' ' + d + ', '+ y +' ' + h + ":" + mm;
  xy += str;
  return xy;
}
export function AmPmDateNoTime(date) {
  let mo ={
    '01':"Jan",
    '02':"Feb",
    '03':"Mar",
    '04':"Apr",
    '05':"May",
    '06':"Jun",
    '07':"Jul",
    '08':"Aug",
    '09':"Sep",
    '10':"Oct",
    '11':"Nov",
    '12':"Dec",
  }
  if(!date){
    return ''
  }
  let time = date.substring(0,19);    
  time = time.replace(/-/g,'/'); 
  let now = new Date(time);
  let str = '';
  let y = now.getFullYear();
  let m = now.getMonth() +1;
  let d = now.getDate();
  let h = now.getHours();
  let mm = now.getMinutes();
  let s = now.getSeconds();
  if(h>=12) {
    h -= 12;
    str = " PM";
  }else{
    str = " AM";
  }
  h = h < 10 ? "0" + h : h;
  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  let xy =mo[m] + ' ' + d + ', '+ y +' ';
  // xy += str;
  return xy;
}
export function AmPmHHmm(date) {
  if(!date){
    return ''
  }
  let time = date.substring(0,19);    
  time = time.replace(/-/g,'/'); 
  let now = new Date(time);
  let str = '';
  let y = now.getFullYear();
  let m = now.getMonth() +1;
  let d = now.getDate();
  let h = now.getHours();
  let mm = now.getMinutes();
  let s = now.getSeconds();
  if(h>=12) {
    h -= 12;
    str = " PM";
  }else{
    str = " AM";
  }
  h = h < 10 ? "0" + h : h;
  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  mm = mm < 10 ? "0" + mm : mm;
  s = s < 10 ? "0" + s : s;
  let xy = h + ":" + mm;
  xy += str;
  return xy;
}