<template>
  <div class="view">
    <top-nav @menu_data="getMenuData" />
    <div class="blank70"></div>
    <div class="top_article" @click="goRouter('/BlogDetails/'+top_article.webblockid)">
      <div class="imgs">
        <img :src="top_article.coverimage+'?x-oss-process=image/resize,m_fill,w_214'" alt="">
      </div>
      <p class="title">{{top_article.title}}</p>
      <p class="sub_title">{{top_article.subtitle}}</p>
      <p class="updatedtimestamp">{{handleAmPmDate(top_article.updatedtimestamp)}}</p>
      <div class="top_article_inside"></div>
    </div>
    <div class="third_content_box">
      <div class="third_title">Recent Blogs</div>
      <div class="third_item_box" v-for="(d,i) in resourceList" :key="i" @click="goRouter('/BlogDetails/'+resourceList[i].webblockid)">
        <div class="left_img_box">
          <img :src="d.coverimage+'?x-oss-process=image/resize,m_fill,h_113,w_164'" alt />
        </div>
        <div class="right_content_box">
          <div class="upper">{{d.title}}</div>
          <div class="lower">{{d.subtitle}}</div>
          <div class="three">{{handleAmPmDate(d.updatedtimestamp)}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="resources_content">
      <p class="recent_articles">Recent Articles</p>
      <div class="inner_card" v-for="(d,i) in resourceList" :key="i" @click="goRouter('/ResourceDetail/'+resourceList[i].webblockid)">
        <div class="first_box">
          <img :src="d.coverimage+'?x-oss-process=image/resize,m_fill,h_170,w_350'" alt />
        </div>
        <div class="second_text">{{d.title}}</div>
        <div class="third_text">{{d.subtitle}}</div>
      </div>
      <div class="blank20"></div>
      <div class="bottom_link">
        <footer-nav />
      </div>
    </div> -->
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
import { getWebBlock } from "@/api/userApi.js";
import { AmPmDateNoTime } from '@/assets/js/time.js'
export default {
  data() {
    return {
      showMenuFlag: "0",
      isShow: false,
      top_article: {},
      resourceList: []
    };
  },
  created() {
    getWebBlock().then(res => {
      
      if (res.data.success) {
        this.top_article = res.data.data.shift();
        
        this.resourceList = res.data.data;
      }
    });
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleAmPmDate(times) {
      return AmPmDateNoTime(times)
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve)
    // FooterNav
  }
};
</script>
<style lang="scss" scoped>
.view {
  background-color: #f8f8f8;
  .blank70 {
    height: 70px;
  }
  .top_article {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 214px;
    .top_article_inside {
      position: absolute;
      width: 100%;
      height: 214px;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .imgs {
      position: absolute;
      width: 100%;
      left: 0;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .title {
      position: absolute;
      top: 102px;
      z-index: 20;
      font-family: Poppins-Bold;
      font-size: 16px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      left: 16px;
      color: #fff;
    }
    .sub_title {
      position: absolute;
      top: 150px;
      z-index: 20;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      left: 16px;
      color: #fff;
    }
    .updatedtimestamp {
      position: absolute;
      top: 190px;
      z-index: 20;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      left: 16px;
      color: #fff;
    }
  }
  .third_content_box {
    padding: 23px 24px 19px;
    .third_title {
      font-family: Poppins-Bold;
      font-size: 16px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
    }
    .third_item_box {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      position: relative;
      text-align: left;
      .left_img_box {
        width: 164px;
        height: 113px;
        overflow: hidden;
        border-radius: 5px;
      }
      .right_content_box {
        margin-left: 15px;
        width: 185px;
        flex: 1;
        position: relative;
        // bottom: 0;
        // right: 0;
        .upper {
          font-family: Poppins-Bold;
          font-size: 14px;
          // font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          overflow: hidden;
          -webkit-line-clamp:2;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .lower {
          margin-top: 8px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
          overflow: hidden;
          -webkit-line-clamp:2;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .three {
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #67788c;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}
</style>